import CheckHomePage from '@/components/CheckHomePage/CheckHomePage'
import { Event, Experiment, Paths, ScreenName } from '@/enums'
import {
    getExperimentsState,
    useScrollToTopOnNavigation,
    useSetAnalyticsAndTracking,
} from '@/hooks'
import '@/styles/global.scss'
import { globalTheme } from '@/styles/themes/global'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import {
    OptimizelyProvider,
    createInstance,
    useExperiment,
} from '@optimizely/react-sdk'
import React, { lazy, memo, Suspense, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import {
    BrowserRouter,
    Redirect,
    Route,
    Switch,
    useLocation,
} from 'react-router-dom'
import RedirectOnCondition from './components/RedirectOnCondition/RedirectOnCondition'
import useAnalytics from './hooks/useAnalytics'
import AkoyaCallback from './pages/AkoyaCallback'
import { getCookie } from './util/cookies'

const HomePage = lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "home-chunk" */
            '@/pages/HomePage'
        )
)
const Questionnaire = lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "questionnaire-chunk" */
            '@/pages/Questionnaire'
        )
)
const JoinUs = lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "join-us-chunk" */
            '@/pages/JoinUs'
        )
)
const ReferralLanding = lazy(
    () =>
        import(
            /* webpackPrefetch: true */
            /* webpackChunkName: "join-us-chunk" */
            '@/pages/Referrals'
        )
)
const Verify = lazy(
    () =>
        import(
            /* webpackChunkName: "verify-chunk" */
            '@/pages/Verify'
        )
)
const YoureIn = lazy(
    () =>
        import(
            /* webpackChunkName: "all-set-chunk" */
            '@/pages/YoureIn'
        )
)
const YoureAllSet = lazy(
    () =>
        import(
            /* webpackChunkName: "all-set-chunk" */
            '@/pages/YoureAllSet'
        )
)
const ResetPassword = lazy(
    () =>
        import(
            /* webpackChunkName: "reset-password-chunk" */
            '@/pages/ResetPassword'
        )
)
const ResetPasswordResult = lazy(
    () =>
        import(
            /* webpackChunkName: "reset-password-result-chunk" */
            '@/pages/ResetPasswordResult'
        )
)

const VerifyDevice = lazy(
    () =>
        import(
            /* webpackChunkName: "verify-device-chunk" */
            '@/pages/VerifyDevice'
        )
)

const VerifyDeviceOutcome = lazy(
    () =>
        import(
            /* webpackChunkName: "verify-device-outcome-chunk" */
            '@/pages/VerifyDeviceOutcome'
        )
)

const VerifyEmail = lazy(
    () =>
        import(
            /* webpackChunkName: "verify-email-chunk" */
            '@/pages/VerifyEmail'
        )
)

const InternationalBlock = lazy(
    () =>
        import(
            /* webpackChunkName: "international-block-chunk" */
            '@/pages/InternationalBlock'
        )
)

const optimizely = createInstance({
    sdkKey: process.env.REACT_APP_OPTIMIZELY_KEY,
    datafileOptions: {
        urlTemplate: process.env.REACT_APP_OPTIMIZELY_TEMPLATE,
    },
})

interface GetNewWebAppRespectiveUrlOptions {
    forwardQueryParams?: boolean
}

const getNewWebAppRespectiveUrl = (
    path: Paths,
    { forwardQueryParams = true }: GetNewWebAppRespectiveUrlOptions = {}
) => {
    const newWebAppRespectiveUrlObject = new URL(
        path,
        process.env.REACT_APP_NEW_WEB_APP_HOST
    )

    if (forwardQueryParams) {
        newWebAppRespectiveUrlObject.search = location.search
    }

    const newWebAppRespectiveUrl = newWebAppRespectiveUrlObject.toString()
    return newWebAppRespectiveUrl
}

export const AppProviders: React.FC = ({ children }) => (
    <React.StrictMode>
        <OptimizelyProvider optimizely={optimizely} timeout={3000}>
            <CssBaseline />
            <ThemeProvider theme={globalTheme}>{children}</ThemeProvider>
        </OptimizelyProvider>
    </React.StrictMode>
)

/**
 * Custom hook to track the visibility of the cookie consent banner. The tracking relies on "AwaitingReconsent".
 * @see https://my.onetrust.com/articles/en_US/Knowledge/UUID-7478d3b4-18eb-3ac0-a6fd-fb7ebff9f8dc for AwaitingReconsent. In short AwaitingReconsent = "false" indicates that users have accepted cookies, therefore banner won't pop up. Otherwise, it may not present or be "true" (banner pops up).
 */
const useTrackCookieConsentBanner = () => {
    const { analytics } = useAnalytics()
    const oneTrustCookie = getCookie('OptanonConsent') as string
    const shouldShowConsentBanner =
        new URLSearchParams(oneTrustCookie).get('AwaitingReconsent') !== 'false'

    useEffect(() => {
        if (!analytics.isLoaded) {
            return
        }

        if (shouldShowConsentBanner) {
            analytics.track(Event.ViewedScreen, {
                screenName: ScreenName.CookieBanner,
            })
        }
    }, [analytics.isLoaded, shouldShowConsentBanner])
}

/**
 * wrapper to cope with re-render up the tree, so the "useTrackCookieConsentBanner" hook doesn't fire multiple times as the "AppRoutesAndSetup" re-renders
 */
const BannerTracker = memo(() => {
    useTrackCookieConsentBanner()
    return null
})

BannerTracker.displayName = 'BannerTracker'

export const AppRoutesAndSetup = () => {
    useSetAnalyticsAndTracking()
    useScrollToTopOnNavigation()
    const [variation] = useExperiment('webapp-cookies-banner')

    const currentLocation = useLocation()
    const pathName = currentLocation.pathname
    if (pathName === '' || pathName === '/') {
        const queryParams = currentLocation.search
        window.location.href = Paths.HomePage + queryParams
        return <></>
    }

    const enableCookiesConsentBanner =
        process.env.REACT_APP_ENABLE_COOKIES_CONSENT_BANNER === 'true'
    const isProdEnv = process.env.APP_ENV === 'production'

    return (
        <Suspense fallback={<></>}>
            {enableCookiesConsentBanner && variation === 'variant' && (
                <Helmet>
                    {/* <!-- OneTrust Cookies Consent Notice start for app.earnin.com --> */}
                    <script
                        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
                        type="text/javascript"
                        data-domain-script={
                            isProdEnv
                                ? '7616773b-e473-4921-b7ce-5c57649159c4'
                                : '7616773b-e473-4921-b7ce-5c57649159c4-test'
                        }
                    />
                    <script type="text/javascript">{`function OptanonWrapper() {}`}</script>
                    {/* <!-- OneTrust Cookies Consent Notice end for app.earnin.com --> */}
                </Helmet>
            )}
            <Switch>
                <Route exact path={Paths.HomePage}>
                    <CheckHomePage>
                        <RedirectOnCondition
                            condition={true}
                            redirectPath={getNewWebAppRespectiveUrl(
                                Paths.HomePage
                            )}
                            isInternalRoute={false}
                        >
                            <HomePage />
                        </RedirectOnCondition>
                    </CheckHomePage>
                </Route>
                <Route path={Paths.Questionnaire}>
                    <RedirectOnCondition
                        condition={true}
                        redirectPath={getNewWebAppRespectiveUrl(
                            Paths.Questionnaire
                        )}
                        isInternalRoute={false}
                    >
                        <Questionnaire />
                    </RedirectOnCondition>
                </Route>
                <Route path={Paths.JoinUs}>
                    <RedirectOnCondition
                        condition={true}
                        redirectPath={getNewWebAppRespectiveUrl(Paths.JoinUs)}
                        isInternalRoute={false}
                    >
                        <JoinUs />
                    </RedirectOnCondition>
                </Route>
                <Route path={Paths.Referrals}>
                    <ReferralLanding />
                </Route>
                <Route path={Paths.YouAreIn}>
                    <YoureIn />
                </Route>
                <Route path={Paths.VerifyPhone}>
                    <Verify />
                </Route>
                <Route path={Paths.YouAreAllSet}>
                    <YoureAllSet />
                </Route>
                <Route path={Paths.VerifyDevice}>
                    <VerifyDevice />
                </Route>
                <Route path={Paths.VerifyDeviceResult}>
                    <VerifyDeviceOutcome />
                </Route>
                <Route path={Paths.VerifyWorkEmail}>
                    <VerifyEmail emailString="work email" />
                </Route>
                <Route path={Paths.VerifyPersonalEmail}>
                    <VerifyEmail emailString="personal email" />
                </Route>
                <Route path={Paths.ResetPassword}>
                    <ResetPassword />
                </Route>
                <Route path={Paths.ResetPasswordResult}>
                    <ResetPasswordResult />
                </Route>
                <Route path={Paths.Blocked}>
                    <InternationalBlock />
                </Route>
                <Route path={Paths.OutsideUsaRegex}>
                    <InternationalBlock />
                </Route>
                <Route path={Paths.AkoyaCallback}>
                    <AkoyaCallback />
                </Route>
                <Route path="/*">
                    <Redirect to={{ pathname: Paths.HomePage }} />
                </Route>
            </Switch>
        </Suspense>
    )
}

export const App = () => (
    <AppProviders>
        <BrowserRouter>
            <AppRoutesAndSetup />
        </BrowserRouter>
    </AppProviders>
)

export default App
