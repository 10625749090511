/**
 * Check the links below for more info on MUI Theme:
 * https://mui.com/customization/theming/
 * https://mui.com/customization/default-theme/
 */

import { createTheme } from '@mui/material/styles'

/**
 * EarnIn new branding/styleguide:
 * https://www.figma.com/file/HDh8UItDlLWalvM1kpIsjy/App-Reskin?node-id=1121%3A105190&t=tfFxWrrHTQoM7PvT-0
 */
export const earninColors = {
    light: {
        content: {
            primary: '#282828',
            secondary: '#686868',
        },
        contentPlaceholder: {
            primary: '#A5A5A5',
        },
        contentFill: {
            primary: '#C5C5C5',
            secondary: '#DDDDDD',
        },
        background: {
            primary: '#FFFFFF',
            secondary: '#F2F2F2',
            tertiary: '#F0F0E8',
            special: '#FFE33B',
        },
        button: {
            primary: '#1F1F1F',
        },
        link: {
            primary: '#4361E1',
        },
        navigationSelected: {
            primary: '#F0736E',
        },
        icon: {
            primary: '#484848',
        },
        alert: {
            primary: '#4361E1',
            secondary: '#EBF2FF',
        },
    },
    dark: {
        content: {
            primary: '#FFFFFF',
            secondary: '#C5C5C5',
        },
        contentPlaceholder: {
            primary: '#686868',
        },
        contentFill: {
            primary: '#484848',
            secondary: '#282828',
        },
        background: {
            primary: '#1F1F1F',
            secondary: '#2F2F2F',
            tertiary: '#F0F0E8',
            special: '#FFE33B',
        },
        button: {
            primary: '#FFFFFF',
        },
        link: {
            primary: '#5082FF',
        },
        navigationSelected: {
            primary: '#FFE33B',
        },
        icon: {
            primary: '#F2F2F2',
        },
        alert: {
            primary: '#5082FF',
            secondary: '#EBF2FF',
        },
    },
}

export const breakpoints = {
    xs: 0,
    sm: 600,
    md: 1024,
    lg: 1440,
    xl: 1920,
    mediaQuery(
        maxOrMin: 'max' | 'min',
        breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl',
        property: 'width' | 'height' = 'width'
    ) {
        return `(${maxOrMin}-${property}: ${this[breakpoint]}px)`
    },
}

export const globalTheme = createTheme({
    breakpoints: {
        values: breakpoints,
    },
    palette: {
        primary: {
            main: earninColors.light.content.primary,
        },
        secondary: {
            main: earninColors.light.content.secondary,
        },
        icon: {
            main: earninColors.light.icon.primary,
        },
        background: {
            default: earninColors.light.background.primary,
            special: earninColors.light.background.special,
        },
        success: {
            main: '#1BD0A3',
        },
        error: {
            main: '#DD3434',
        },
        warning: {
            main: '#FFC850',
        },
    },
    typography: {
        fontFamily: [
            'EarnInMori',
            '-apple-system',
            'BlinkMacSystemFont',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            '"Fira Sans"',
            '"Droid Sans"',
            '"Helvetica Neue"',
            'sans-serif',
        ].join(','),
        h1: {
            color: earninColors.light.content.primary,
            fontWeight: 400,
            fontSize: '5.5rem',
            lineHeight: 1,
            [`@media ${breakpoints.mediaQuery('max', 'lg')}`]: {
                fontSize: '3rem',
            },
            [`@media ${breakpoints.mediaQuery('max', 'sm')}`]: {
                fontSize: '2rem',
            },
        },
        h2: {
            color: earninColors.light.content.primary,
            fontWeight: 600,
            lineHeight: '44px',
            fontSize: '2.625rem',
            [`@media ${breakpoints.mediaQuery('max', 'sm')}`]: {
                fontSize: '2.25rem',
                lineHeight: '40px',
            },
        },
        h3: {
            color: earninColors.light.content.primary,
            fontWeight: 900,
            fontSize: '1.75rem',
        },
        h4: {
            color: earninColors.light.content.primary,
        },
        h5: {
            color: earninColors.light.content.primary,
        },
        h6: {
            color: earninColors.light.content.primary,
        },
        subtitle1: {
            color: earninColors.light.content.secondary,
        },
        subtitle2: {
            color: earninColors.light.content.secondary,
            fontSize: '0.75rem',
        },
        body1: {
            color: earninColors.light.content.secondary,
            lineHeight: 1.2,
        },
        body2: {
            color: earninColors.light.content.secondary,
            fontWeight: 400,
            fontSize: '1.625rem',
            lineHeight: '30px',
            [`@media ${breakpoints.mediaQuery('max', 'sm')}`]: {
                fontSize: '1.25rem',
                lineHeight: '24px',
            },
        },
        button: {
            color: earninColors.light.content.primary,
            fontWeight: 600,
            textTransform: 'none',
        },
        caption: {
            color: earninColors.light.content.secondary,
            fontSize: '0.875rem',
            lineHeight: 1.45,
            letterSpacing: '-0.32px',
            textAlign: 'left',
        },
        overline: {
            color: earninColors.light.content.secondary,
        },
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    wordBreak: 'break-word',
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: earninColors.light.link.primary,
                    textDecoration: 'none',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: earninColors.light.content.secondary,
                    paddingLeft: '1rem',
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: earninColors.light.content.secondary,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '&': {
                        borderRadius: '40px',
                    },
                    '& > fieldset': {
                        paddingLeft: '1.125rem',
                        border: '1px solid #F3F2F7',
                    },
                },
                input: {
                    color: earninColors.light.content.primary,

                    '&': {
                        paddingLeft: '1.5em',
                    },

                    '&:-webkit-autofill': {
                        transitionDelay: '9999999s',
                        transitionProperty: 'background-color, color',
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    fieldset: {
                        borderColor: earninColors.light.content.primary,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '30px',
                    boxShadow: 'none !important',
                    padding: '1rem 1.875rem',
                    margin: '0.25rem 0',
                    fontWeight: 'bold',
                    width: '100%',
                    minWidth: '200px',
                    maxWidth: '20rem',
                    '&.MuiButton-containedPrimary:not(.Mui-disabled)': {
                        backgroundColor: earninColors.dark.background.primary,
                    },
                    '&.Mui-disabled': {
                        cursor: 'not-allowed',
                        pointerEvents: 'initial',
                    },
                },
                outlined: {
                    borderWidth: '2px !important',
                    borderColor: earninColors.light.button.primary,
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: '20px',
                    padding: '1rem .5rem',
                    [`@media ${breakpoints.mediaQuery('min', 'sm')}`]: {
                        padding: '3rem',
                    },
                },
            },
        },
    },
})

export type IGlobalTheme = Partial<typeof globalTheme>

export default globalTheme
