import { Paths, ScreenName } from '@/enums'
import Page from '@/components/Page'
import { Grid, Typography } from '@mui/material'
import TrackedButton from '@/components/TrackedButton'
import { useEffect } from 'react'

export type IAkoyaCallback = React.FC

export const AkoyaCallback: IAkoyaCallback = () => {
    const openAkoyaDeeplinkUrl = () => {
        const akoyaDeeplinkUrlObject = new URL(Paths.AkoyaDeepLink)
        akoyaDeeplinkUrlObject.search = location.search
        const akoyaDeeplinkUrl = akoyaDeeplinkUrlObject.toString()
        window.location.href = akoyaDeeplinkUrl
    }
    const renderOpenEarninBtn = () => (
        <TrackedButton
            name="open-earnin"
            onClick={openAkoyaDeeplinkUrl}
            sx={{
                marginTop: '2rem',
            }}
        >
            Return to EarnIn
        </TrackedButton>
    )
    useEffect(() => {
        openAkoyaDeeplinkUrl()
    }, [])

    return (
        <Page screenName={ScreenName.AkoyaCallback} centerLogo>
            <Grid
                container
                sx={{
                    alignItems: 'center',
                    textAlign: 'center',
                    justifyContent: 'center',
                }}
            >
                <Grid item xs={8}>
                    <Typography id="good-to-go" gutterBottom variant="body2">
                        Return to the EarnIn app to finish linking your account
                    </Typography>
                    {renderOpenEarninBtn()}
                </Grid>
            </Grid>
        </Page>
    )
}

export default AkoyaCallback
