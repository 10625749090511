/**
 * Feature flag IDs in optimizely
 */

export enum Feature {
    smsComplianceSubscription = 'sms-compliance-subscription-group',
    newHomePage = 'webapp-test-new-home-page',
}

export default Feature
