import pause from '@/util/pause'

export type IPollUntilResolvedOptions = {
    maxAttempts?: number
    poolIntervalMs?: number
    unresolvedValues?: Array<any>
}
export type IPollUntilResolved = <ResolvedValueType = unknown>(
    poolFn: () => undefined | ResolvedValueType,
    options?: IPollUntilResolvedOptions
) => Promise<ResolvedValueType>

const pollUntilResolved = async <ResolvedValueType,>(
    pollFn: () => ResolvedValueType,
    {
        maxAttempts = 5,
        poolIntervalMs = 1000,
        unresolvedValues = undefined,
    }: IPollUntilResolvedOptions = {}
): Promise<ResolvedValueType> =>
    new Promise(async (resolve, reject) => {
        try {
            let attempts = 0
            while (attempts < maxAttempts) {
                const resolvedValue = await pollFn()
                if (
                    resolvedValue !== undefined &&
                    !unresolvedValues?.includes(resolvedValue)
                ) {
                    return resolve(resolvedValue)
                }
                attempts += 1
                await pause(poolIntervalMs)
            }
            return reject(new Error('pollUntilResolved: max attempts reached'))
        } catch (err) {
            return reject(err)
        }
    })

export default pollUntilResolved
