/**
 * Keys used for localStorage
 */

export enum LocalStorage {
    UtmCampaign = 'utmCampaign',
    UtmContent = 'utmContent',
    UtmSource = 'utmSource',
    UtmMedium = 'utmMedium',
    Experiments = 'experiments',
    DeviceId = 'deviceId',
    AnonymousUserId = 'ajs_anonymous_id',
    PublicUserId = 'ajs_user_id',
    EarninRegistrationPhone = 'earnin_registration_phone',
    AllowAccessYouAreIn = 'allow_access_you_are_in',
}

export default LocalStorage
