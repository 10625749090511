import { Feature } from '@/enums'
import { useStore } from '@/hooks'
import { useFeature as useOptimizelyFeature } from '@optimizely/react-sdk'
import { useCallback, useEffect, useMemo } from 'react'

export const useFeature = <VariablesType extends Record<string, any>>(
    feature: Feature
) => {
    const { set: setFeatureFlagsState } = useStore(
        useCallback((state) => state.featureFlags, [])
    )

    const [isFeatureActive, variables, isReady] = useOptimizelyFeature(feature)

    const returnValue = useMemo(
        () => ({
            isActive: isFeatureActive,
            variables: variables as VariablesType,
            isReady,
        }),
        [isFeatureActive, variables, isReady]
    )

    useEffect(() => {
        setFeatureFlagsState(feature, returnValue)
    }, [feature, returnValue, setFeatureFlagsState])

    return returnValue
}

export default useFeature
