import { useRef, useState } from 'react'

type WrappedFunction<
    ParamsType extends { [key: string]: any } | undefined =
        | Record<string, any>
        | undefined,
    ResType = any,
    ErrType = Error,
    FuncResType = any
> = (options?: {
    params?: ParamsType
    abortSignal?: AbortSignal
    currentValue?: Awaited<ResType>
    currentError?: ErrType
}) => Promise<Awaited<FuncResType>>

export type IUseAsyncFnHandler<
    ParamsType extends { [key: string]: any } | undefined =
        | Record<string, any>
        | undefined,
    ResType = any
> = (params?: ParamsType) => Promise<Awaited<ResType>>

export type IUseAsyncFn = <
    ParamsType extends { [key: string]: any } | undefined =
        | Record<string, any>
        | undefined,
    ResType = any,
    ErrType = Error,
    FuncResType = any
>(
    fn: WrappedFunction<ParamsType, ResType, ErrType, FuncResType>,
    options?: {
        handleAs?: 'tryCatch' | 'tuple'
        returnAs?: 'tryCatch' | 'tuple'
        registerAbortErrors?: boolean
        initialValue?:
            | ResType
            | (ResType extends Array<any>
                  ? []
                  : ResType extends ReadonlyArray<any>
                  ? []
                  : ResType extends Record<any, any>
                  ? Record<string, never>
                  : any)
    }
) => [
    IUseAsyncFnHandler<ParamsType, ReturnType<typeof fn>>,
    {
        loading: boolean
        error: Awaited<ErrType>
        value: Awaited<ResType>
        abort?: () => void
    }
]

export const useAsyncFn: IUseAsyncFn = (
    fn,
    {
        initialValue,
        handleAs = 'tuple',
        returnAs = 'tuple',
        registerAbortErrors = false,
    } = {}
) => {
    // ?NOTE: using a state to track the value of 'loading' was not working well with the use of 'AbortSignal.abort' because it was continuosly overriding the state to 'false' when abortion was done. The method below is a workaround that seems to work fine for determining if there are pending requests (i.e. loading = true).
    const [pendingRequestsQty, setPendingRequestsQty] = useState(0)
    const [finishedRequestsQty, setFinishedRequestsQty] = useState(0)
    const loading = !!(pendingRequestsQty - finishedRequestsQty)
    const [value, setValue] = useState<any>(initialValue)
    const [error, setError] = useState<any>()
    const abortControllerRef = useRef<AbortController>()

    const handleError = (_error: Error) => {
        setFinishedRequestsQty(pendingRequestsQty + 1)
        if (_error?.name === 'AbortError' && !registerAbortErrors) {
            setError(null)
        } else {
            setError(_error)
        }

        setValue(null)

        if (returnAs === 'tuple') {
            return [null, _error]
        } else {
            return Promise.reject(_error)
        }
    }

    const handleFn: IUseAsyncFnHandler = async (params: any) => {
        try {
            setError(null)

            abortControllerRef.current?.abort()
            abortControllerRef.current = new AbortController()
            const abortSignal = abortControllerRef.current.signal
            setPendingRequestsQty(pendingRequestsQty + 1)

            const response = fn({
                params,
                abortSignal,
                currentValue: value,
                currentError: error,
            })

            response
                .then((awaitedResponse) => {
                    setFinishedRequestsQty(pendingRequestsQty + 1)
                    let fnResponse: any
                    let fnError: Error | null = null
                    if (handleAs === 'tuple' && awaitedResponse) {
                        ;[fnResponse, fnError] = awaitedResponse as any
                        setValue(fnResponse)
                        if (fnError instanceof Error) {
                            if (
                                fnError?.name === 'AbortError' &&
                                !registerAbortErrors
                            ) {
                                setError(null)
                            } else {
                                setError(fnError)
                            }
                        }
                    } else {
                        fnResponse = awaitedResponse
                        setValue(awaitedResponse)
                    }

                    if (returnAs === 'tuple') {
                        return [fnResponse, fnError]
                    } else {
                        return fnResponse
                    }
                })
                .catch(handleError)

            return response
        } catch (_error) {
            return handleError(_error as Error)
        }
    }

    return [
        handleFn,
        {
            loading,
            value,
            error,
            abort: () => {
                try {
                    abortControllerRef.current?.abort()
                } catch {
                    //
                }
            },
        },
    ]
}

export default useAsyncFn
