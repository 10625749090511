import hotjar from '@/util/hotjar'
import logger from '@/util/logger'
import { OptimizelyContext } from '@optimizely/react-sdk'
import { useContext, useEffect } from 'react'
import useAsyncEffect from 'use-async-effect'
import { useStore } from '@/hooks'
import '@/hooks/useExperiment' // this is done to read the 'skip_experiment' query parameter when the app loads, do not remove

/**
 * Loads identity for Hotjar and Optimizely.
 *
 * Ideally, this hook should use "useSession" or "useAnalytics" to get the data related to the user,
 * there is currently a race-condition problem that happens with Optimizely that forces us to run this
 * code with as few renders as possible to avoid the race condition.
 *
 * HOTJAR
 * Uses "userId" (which will be "null" for users not logged in) and "anonymousId" as custom attribute
 *
 * OPTIMIZELY
 * Uses whatever identification is available (userId, anonymousUserId or deviceId)
 */
export type IUseSetAnalyticsAndTracking = () => void
export const useSetAnalyticsAndTracking: IUseSetAnalyticsAndTracking = () => {
    const { publicUserId, anonymousUserId, deviceId } = useStore(
        (state) => state.session
    )
    const { optimizely } = useContext(OptimizelyContext)

    const availableUserId = publicUserId || anonymousUserId || deviceId || null

    // setup optimizely
    useEffect(() => {
        try {
            optimizely?.setUser({
                id: availableUserId,
                attributes: {
                    publicUserId,
                    anonymousUserId,
                    deviceId,
                },
            })
        } catch (err: any) {
            logger.error(String(err), err)
        }
    }, [optimizely, availableUserId])

    // setup hotjar
    useAsyncEffect(
        async (isActive) => {
            try {
                if (isActive()) {
                    await hotjar.waitForReadiness()
                    hotjar.identify(availableUserId, {
                        publicUserId,
                        anonymousUserId,
                        deviceId,
                    })
                }
            } catch (err: any) {
                logger.error(String(err), err)
            }
        },
        [availableUserId]
    )
}

export default useSetAnalyticsAndTracking
