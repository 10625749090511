import { Paths } from '@/enums'
import useStore from '@/store'

export function useFunnelStep(
    step: string
): [string | undefined, () => void, number] {
    const completeFunnelStep = useStore((state) => state.completeFunnelStep)
    const completedFunnelSteps = useStore.getState().completedFunnelSteps
    let redirect
    const stepDependencies = new Map<string, string>([
        [Paths.YouAreAllSet, Paths.VerifyPhone],
        [Paths.YouAreIn, Paths.VerifyPhone],
        [Paths.VerifyPhone, Paths.JoinUs],
        [Paths.JoinUs, Paths.Questionnaire],
        [Paths.Questionnaire, Paths.HomePage],
    ])
    const stepIndices = new Map<string, number>([
        [Paths.HomePage, 0],
        [Paths.Questionnaire, 1],
        [Paths.JoinUs, 2],
        [Paths.VerifyPhone, 3],
        [Paths.YouAreIn, 4],
        [Paths.YouAreAllSet, 5],
    ])

    const baseStep = Paths.HomePage
    const completeCurrentFunnelStep = () => {
        completeFunnelStep(step)
    }
    const dependency = stepDependencies.get(step)
    let index = -1

    if (dependency && !completedFunnelSteps.has(dependency)) {
        redirect =
            completedFunnelSteps.size > 0
                ? [...completedFunnelSteps].reverse()[0]
                : baseStep
    } else {
        index = stepIndices.get(step) ?? index
    }
    return [redirect, completeCurrentFunnelStep, index]
}

export default useFunnelStep
