import { Event } from '@/enums'
import React, { RefObject, useEffect, useMemo, useRef } from 'react'
import ReactGoogleRecaptchaV2 from 'react-google-recaptcha'
import useAnalytics from '../useAnalytics'

export type IRecaptchaV2SuccessCallback = (token: string | null) => void
export type IRecaptchaV2SuccessCallbackCurry = (
    func?: IRecaptchaV2SuccessCallback
) => IRecaptchaV2SuccessCallback

export type IRecaptchaV2ErrorCallback = (error?: any) => void
export type IRecaptchaV2ErrorCallbackCurry = (
    func?: IRecaptchaV2ErrorCallback
) => IRecaptchaV2ErrorCallback

export type IRecaptchaV2ExpiredCallback = () => void
export type IRecaptchaV2ExpiredCallbackCurry = (
    func?: IRecaptchaV2ExpiredCallback
) => IRecaptchaV2ExpiredCallback

export type IExecuteRecaptchaOptions = {
    reset: boolean
}

export type IExecuteRecaptcha = (options?: {
    executeRecaptchaOptions?: IExecuteRecaptchaOptions | {}
    analyticsProperties?: {
        captchaFor: string
        elementName?: never
        [key: string]: unknown
    }
}) => Promise<string>

export type IUseRecaptchaOptions = {
    sitekey?: string
    hideRecaptchaBadge?: boolean
    size?: 'normal' | 'compact' | 'invisible'
    badge?: 'bottomright' | 'bottomleft' | 'inline'
    language?:
        | 'ar'
        | 'af'
        | 'am'
        | 'hy'
        | 'az'
        | 'eu'
        | 'bn'
        | 'bg'
        | 'ca'
        | 'zh-HK'
        | 'zh-CN'
        | 'zh-TW'
        | 'hr'
        | 'cs'
        | 'da'
        | 'nl'
        | 'en-GB'
        | 'en'
        | 'et'
        | 'fil'
        | 'fi'
        | 'fr'
        | 'fr-CA'
        | 'gl'
        | 'Value'
        | 'ka'
        | 'de'
        | 'de-AT'
        | 'de-CH'
        | 'el'
        | 'gu'
        | 'iw'
        | 'hi'
        | 'hu'
        | 'is'
        | 'id'
        | 'it'
        | 'ja'
        | 'kn'
        | 'ko'
        | 'lo'
        | 'lv'
        | 'lt'
        | 'ms'
        | 'ml'
        | 'mr'
        | 'mn'
        | 'no'
        | 'fa'
        | 'Value'
        | 'pl'
        | 'pt'
        | 'pt-BR'
        | 'pt-PT'
        | 'ro'
        | 'ru'
        | 'sr'
        | 'si'
        | 'sk'
        | 'sl'
        | 'es'
        | 'es-419'
        | 'sw'
        | 'sv'
        | 'ta'
        | 'te'
        | 'th'
        | 'tr'
        | 'uk'
        | 'ur'
        | 'vi'
        | 'zu'
}

export type IRecaptchaV2 = React.FC<
    Partial<IUseRecaptchaOptions> & {
        onSuccess?: IRecaptchaV2SuccessCallback
        onError?: IRecaptchaV2ErrorCallback
        onExpired?: IRecaptchaV2ExpiredCallback
    }
>

export type IUseRecaptchaV2 = (options?: IUseRecaptchaOptions | {}) => {
    RecaptchaV2: IRecaptchaV2
    executeRecaptcha: IExecuteRecaptcha
}

const registrationSiteKey =
    process.env.REACT_APP_GOOGLE_RECAPTCHA_REGISTRATION_SITEKEY

export const useRecaptchaV2: IUseRecaptchaV2 = (useRecaptchaOptions = {}) => {
    // 1. variables/state
    const { analytics } = useAnalytics()
    const recaptchaContainerRef = useRef<Node>()
    const recaptchaContainerWasVisibileRef = useRef<boolean>(false)
    const recaptchaContainerObserverRef = useRef<MutationObserver>()
    const analyticsPropertiesRef = useRef<Record<string, any>>({})
    const {
        language: _language = 'en',
        sitekey: _sitekey = registrationSiteKey,
        hideRecaptchaBadge: _hideRecaptchaBadge = false,
        size: _size = 'invisible',
        badge: _badge = 'bottomright',
    } = useRecaptchaOptions as IUseRecaptchaOptions

    const reactGoogleRecaptchaV2Ref = useRef(
        null
    ) as RefObject<ReactGoogleRecaptchaV2>
    const resolverRef = useRef<(value: any) => void>()
    const rejecterRef = useRef<(value: any) => void>()

    // 2. effects
    // 2.1 applies a mutation observer on the "body" to capture a reference to the recaptcha container div
    // when it is added to the DOM and then applies a mutation observer to the recaptcha container div
    // to identify when it becomes visible, so that we can trigger analytics events for it
    useEffect(() => {
        // 2.1.1 recaptcha container mutation observer code, which observes attribute changes (specifically style.visibility)
        // to determine when the recaptcha challenge becomes visible to the user so that we can trigger an analytics event for it
        const recaptchaContainerObserverConfig: MutationObserverInit = {
            attributes: true,
        }
        recaptchaContainerObserverRef.current = new MutationObserver(
            (
                mutationList: Array<MutationRecord>,
                _recaptchaContainerObserver: MutationObserver
            ) => {
                for (const mutation of mutationList) {
                    const recaptchaContainerEl = mutation.target as HTMLElement

                    // if recaptcha container is visible, then...
                    if (recaptchaContainerEl.style.visibility === 'visible') {
                        // if the recaptcha container was not visible before it means that
                        // the challenge was displayed to the user, so we trigger the analytics event
                        if (!recaptchaContainerWasVisibileRef.current) {
                            recaptchaContainerWasVisibileRef.current = true
                            analytics?.track(Event.UserInteracted, {
                                ...analyticsPropertiesRef.current,
                                elementName: 'Captcha challenge displayed',
                            })
                            break
                        }

                        // the code below does some positioning adjusts to the recaptcha challenge
                        // so that it is not displayed off the user's screen
                        // Obs: a potential alternative to this would be to set the 'position' to 'fixed'
                        const expectedTopAttrValue =
                            window.scrollY + window.innerHeight / 10
                        const actualTopAttrValue = Number(
                            recaptchaContainerEl.style.top.split('px')[0]
                        )
                        const matchesExpectedTopAttrValue =
                            expectedTopAttrValue === actualTopAttrValue
                        if (!matchesExpectedTopAttrValue) {
                            recaptchaContainerEl.style.transition = ''
                            recaptchaContainerEl.style.top = `${
                                window.scrollY + window.innerHeight / 10
                            }px`
                        }
                    }

                    if (recaptchaContainerEl.style.visibility === 'hidden') {
                        if (recaptchaContainerWasVisibileRef.current) {
                            rejecterRef?.current?.('Recaptcha closed')
                        }
                        recaptchaContainerWasVisibileRef.current = false
                    }
                }
            }
        )

        // 2.1.2 body mutation observer code, used to identify the recaptcha container and trigger the attribute mutation observer on it
        const bodyObserverTargetNode: Node = document.body
        const bodyObserverConfig: MutationObserverInit = {
            childList: true,
        }
        const bodyObserver = new MutationObserver(
            (
                mutationList: Array<MutationRecord>,
                _bodyObserver: MutationObserver
            ) => {
                for (const mutation of mutationList) {
                    for (const node of mutation.addedNodes) {
                        // TODO: we might need to revise the condition below as it seems very volatile/breakable, but it is the best we have for now
                        const isRecaptchaContainer =
                            (node as HTMLElement)
                                .querySelector('iframe')
                                ?.title?.toLowerCase() ===
                            'recaptcha challenge expires in two minutes'

                        if (isRecaptchaContainer) {
                            recaptchaContainerRef.current = node
                        }
                    }
                }
                if (recaptchaContainerRef?.current) {
                    recaptchaContainerObserverRef.current?.observe(
                        recaptchaContainerRef?.current as Node,
                        recaptchaContainerObserverConfig
                    )
                }
            }
        )
        bodyObserver.observe(bodyObserverTargetNode, bodyObserverConfig)

        return () => {
            bodyObserver.disconnect()
            recaptchaContainerObserverRef.current?.disconnect()
            ;(recaptchaContainerRef.current as HTMLElement)?.remove()
        }
    }, [])

    // 3. recaptcha callback handler functions
    const recaptchaSuccessCallback: IRecaptchaV2SuccessCallbackCurry =
        (onSuccess?: IRecaptchaV2SuccessCallback) => (token: string | null) => {
            recaptchaContainerWasVisibileRef.current = false
            analyticsPropertiesRef.current = {}
            if (typeof onSuccess === 'function') {
                onSuccess(token)
            } else {
                resolverRef?.current?.(token)
            }
        }

    const recaptchaErrorCallback: IRecaptchaV2ErrorCallbackCurry =
        (onError?: IRecaptchaV2ErrorCallback) => (err: any) => {
            recaptchaContainerWasVisibileRef.current = false
            analyticsPropertiesRef.current = {}
            if (typeof onError === 'function') {
                onError(err)
            } else {
                rejecterRef?.current?.(err)
            }
        }

    const recaptchaExpiredCallback: IRecaptchaV2ExpiredCallbackCurry =
        (onExpired?: IRecaptchaV2ExpiredCallback) => () => {
            recaptchaContainerWasVisibileRef.current = false
            analyticsPropertiesRef.current = {}
            if (typeof onExpired === 'function') {
                onExpired()
            }
        }

    // 4. main hook objects
    // 4.1 RecaptchaV2 component
    const RecaptchaV2: IRecaptchaV2 = useMemo(() => {
        const _RecaptchaV2: IRecaptchaV2 = ({
            onSuccess,
            onExpired,
            onError,
            language: languageProp,
            sitekey: sitekeyProp,
            hideRecaptchaBadge: hideRecaptchaBadgeProp,
            size: sizeProp,
            badge: badgeProp,
        }) => {
            const language = languageProp || _language
            const sitekey = sitekeyProp || _sitekey
            const hideRecaptchaBadge =
                hideRecaptchaBadgeProp || _hideRecaptchaBadge
            const size = sizeProp || _size
            const badge = badgeProp || _badge

            return (
                <ReactGoogleRecaptchaV2
                    ref={reactGoogleRecaptchaV2Ref}
                    sitekey={sitekey}
                    onChange={recaptchaSuccessCallback(onSuccess)}
                    onErrored={recaptchaErrorCallback(onError)}
                    onExpired={recaptchaExpiredCallback(onExpired)}
                    hl={language}
                    size={size}
                    badge={badge}
                    {...(hideRecaptchaBadge && { hidden: true })}
                />
            )
        }
        return _RecaptchaV2
    }, [])

    // 4.2 executeRecaptcha helper
    const executeRecaptcha: IExecuteRecaptcha = ({
        executeRecaptchaOptions = {},
        analyticsProperties = {},
    } = {}) =>
        new Promise((resolve, reject) => {
            analyticsPropertiesRef.current = analyticsProperties
            const { reset = true } =
                executeRecaptchaOptions as IExecuteRecaptchaOptions

            const reactGoogleRecaptchaV2Instance =
                reactGoogleRecaptchaV2Ref.current
            if (!reactGoogleRecaptchaV2Instance) {
                throw new Error('Recaptcha is not ready')
            }

            resolverRef.current = (...args) => {
                try {
                    if (reset) {
                        reactGoogleRecaptchaV2Instance.reset()
                    }
                } finally {
                    resolve(...args)
                }
            }

            rejecterRef.current = (...args) => {
                try {
                    if (reset) {
                        reactGoogleRecaptchaV2Instance.reset()
                    }
                } finally {
                    reject(...args)
                }
            }

            reactGoogleRecaptchaV2Instance.execute()

            // the code below does some positioning adjusts to the recaptcha challenge
            // so that it is not displayed off the user's screen
            // Obs: a potential alternative to this would be to set the 'position' to 'fixed'
            if (recaptchaContainerRef.current) {
                setTimeout(() => {
                    const recaptchaContainerEl =
                        recaptchaContainerRef.current as HTMLElement
                    recaptchaContainerEl.style.transition +=
                        ', top 0.3s ease 0s'
                    recaptchaContainerEl.style.top = `${
                        window.scrollY + window.innerHeight / 10
                    }px`
                }, 500)
            }
        })

    return { RecaptchaV2, executeRecaptcha }
}

export default useRecaptchaV2
