///<reference types="segment-analytics" />
import { LocalStorage } from '@/enums'
import { v4 as uuidv4 } from 'uuid'
import pollUntilResolved, {
    IPollUntilResolvedOptions,
} from '@/util/pollUntilResolved'
import * as cookies from '@/util/cookies'
import logger from '../logger'

let _isSegmentAnalyticsScriptLoaded = false

class Queue {
    queue: Array<any>
    size: number

    constructor(size: number) {
        this.queue = []
        this.size = size
    }

    enqueue(element: unknown) {
        this.queue.push(element)

        while (this.queue.length > this.size) {
            this.dequeue()
        }
    }

    dequeue() {
        return this.queue.shift()
    }

    isEmpty() {
        return this.queue.length === 0
    }
}

export class Analytics {
    status: 'not loaded' | 'loading' | 'loaded' | 'failed'
    private _queue: Queue
    private _loadingPromise: Promise<any> | null

    constructor() {
        this._queue = new Queue(100)
        this._loadingPromise = null
        if (_isSegmentAnalyticsScriptLoaded) {
            this.status = 'loaded'
        } else {
            this.status = 'not loaded'
            this.waitForReadiness()
        }
    }

    get isLoaded() {
        return this.status === 'loaded'
    }

    get segment() {
        // if (!this.isLoaded) {
        //     throw new Error('Segment is not loaded')
        // }
        return window?.analytics
    }

    get dataLayer() {
        return window?.dataLayer
    }

    waitForReadiness({
        cb,
        options = {},
    }: {
        cb?: () => unknown
        options?: IPollUntilResolvedOptions
    } = {}): Promise<unknown> {
        if (this.isLoaded) {
            return Promise.resolve(cb ? cb() : true)
        }

        if (
            this.status === 'loading' &&
            this._loadingPromise instanceof Promise
        ) {
            return this._loadingPromise.then(() => (cb ? cb() : true))
        }

        this.status = 'loading'
        this._loadingPromise = new Promise(async (resolve, reject) => {
            try {
                const segmentAnalytics = await pollUntilResolved<
                    Window['analytics']
                >(() => this.segment, {
                    maxAttempts: 100,
                    poolIntervalMs: 100,
                    ...options,
                })
                segmentAnalytics?.ready?.(() => {
                    this.flush()
                    this.status = 'loaded'
                    _isSegmentAnalyticsScriptLoaded = true
                    resolve(cb ? cb() : true)
                })
            } catch (err) {
                this.status = 'failed'
                const error = new Error('Failed to load Segment Analytics')
                reject(error)
            }
        })

        return this._loadingPromise
    }

    async flush() {
        while (!this._queue.isEmpty()) {
            const fn = this._queue.dequeue()
            try {
                await fn()
            } catch (err) {
                console.error(err)
            }
        }
    }

    async callOrQueue(fn: (...args: any) => any) {
        if (this.isLoaded) {
            return fn()
        } else {
            this._queue.enqueue(fn)
        }
    }

    async track(
        eventName: string,
        properties?: Record<string, any>
    ): Promise<null> {
        const _track = () =>
            new Promise(async (resolve, reject) => {
                try {
                    const defaultOptions = this.getDefaultOptions()
                    this?.segment?.track(
                        eventName,
                        properties,
                        defaultOptions,
                        () => {
                            if (
                                process.env
                                    .REACT_APP_ENABLE_ANALYTICS_TRACKING_DEBUG ===
                                'true'
                            ) {
                                console.group(
                                    '=== ANALYTICS TRACKING DEBUG ==='
                                )
                                console.log({
                                    eventName,
                                    properties,
                                })
                                console.groupEnd()
                            }
                            resolve(null)
                        }
                    )
                    this?.dataLayer?.push?.({
                        event: eventName,
                        ...defaultOptions,
                        ...properties,
                    })
                } catch (err) {
                    logger.error(
                        `Failed to execute "analytics.track" for event="${eventName}": ${String(
                            err
                        )}`,
                        err as Error
                    )
                }
            })

        return this.callOrQueue(_track)
    }

    async page() {
        const _page = () =>
            new Promise(async (resolve, reject) => {
                try {
                    const defaultOptions = this.getDefaultOptions()
                    this?.segment?.page({}, defaultOptions, () => {
                        resolve(null)
                    })
                } catch (err) {
                    logger.error(
                        `Failed to execute "analytics.page": ${String(err)}`,
                        err as Error
                    )
                }
            })

        return this.callOrQueue(_page)
    }

    async identify(
        publicUserId: string,
        traits?: Record<string, any>
    ): Promise<unknown>
    async identify(traits?: Record<string, any>): Promise<unknown>
    async identify(
        publicUserIdOrTraits?: string | Record<string, any>,
        traits?: Record<string, any>
    ): Promise<unknown> {
        const _identify = () =>
            new Promise(async (resolve, reject) => {
                const publicUserId =
                    typeof publicUserIdOrTraits === 'string'
                        ? publicUserIdOrTraits
                        : null
                const defaultOptions = this.getDefaultOptions()
                try {
                    if (publicUserId) {
                        this?.segment?.identify(
                            publicUserId,
                            traits,
                            defaultOptions,
                            () => {
                                resolve(null)
                            }
                        )
                    } else {
                        this?.segment?.identify(traits, defaultOptions, () => {
                            resolve(null)
                        })
                    }
                } catch (err) {
                    logger.error(
                        `Failed to execute "analytics.identify" for publicUserId="${publicUserId}": ${String(
                            err
                        )}`,
                        err as Error
                    )
                }
            })

        return this.callOrQueue(_identify)
    }

    getDefaultOptions() {
        const deviceId = this.getDeviceId()
        return {
            context: {
                device: {
                    id: deviceId,
                },
            },
        }
    }

    getUserId() {
        let publicUserId = null

        if (this?.segment?.user) {
            publicUserId = this?.segment?.user?.()?.id()
        } else {
            publicUserId =
                localStorage.getItem(LocalStorage.PublicUserId) ||
                document.cookie
                    ?.split('; ')
                    ?.find((row) =>
                        row.startsWith(`${LocalStorage.PublicUserId}=`)
                    )
                    ?.split('=')?.[1] ||
                ''
        }

        if (
            !publicUserId ||
            publicUserId?.toLocaleLowerCase().trim() === 'null'
        ) {
            publicUserId = null
        }

        return publicUserId
    }

    getAnonymousUserId() {
        let anonymousUserId = null

        if (this?.segment?.user) {
            anonymousUserId = this.segment.user?.()?.anonymousId?.()
        } else {
            anonymousUserId =
                localStorage.getItem(LocalStorage.AnonymousUserId) ||
                cookies.getCookie(LocalStorage.AnonymousUserId) ||
                ''
            anonymousUserId = decodeURI(anonymousUserId)
            anonymousUserId = anonymousUserId.replace(/"/gi, '')
        }

        if (
            !anonymousUserId ||
            anonymousUserId?.toLocaleLowerCase().trim() === 'null'
        ) {
            anonymousUserId = uuidv4()
            localStorage.setItem(LocalStorage.AnonymousUserId, anonymousUserId)
        }

        return anonymousUserId
    }

    getDeviceId() {
        const deviceId = cookies.getCookie(LocalStorage.DeviceId) || uuidv4()

        // Set/refresh deviceId cookie
        const cookieSameSitePolicyOptions = ['strict', 'lax', 'none'] as const
        const cookieSameSitePolicy = process.env
            .REACT_APP_DEVICE_ID_COOKIE_SAMESITE as (typeof cookieSameSitePolicyOptions)[number]
        const cookieMaxAge = Number(
            process.env.REACT_APP_DEVICE_ID_COOKIE_MAXAGE
        )

        cookies.setCookie(LocalStorage.DeviceId, deviceId, {
            domain:
                process.env.REACT_APP_DEVICE_ID_COOKIE_DOMAIN || 'earnin.com',
            sameSite: cookieSameSitePolicyOptions.includes(cookieSameSitePolicy)
                ? cookieSameSitePolicy
                : 'strict', // 'strict' by default
            secure: process.env.REACT_APP_DEVICE_ID_COOKIE_SECURE !== 'false', // will be 'true', unless it is explicitly 'false',
            httpOnly:
                process.env.REACT_APP_DEVICE_ID_COOKIE_HTTPONLY === 'true', // will be 'false', unless it is explicitly 'true',
            maxAge: !Number.isNaN(cookieMaxAge) ? cookieMaxAge : 1707109200, // 400 days by default, max possible value as per https://developer.chrome.com/blog/cookie-max-age-expires/,,
        })

        return deviceId
    }
}

export const analytics = new Analytics()

export default analytics
