import flat from 'flat'

export type IGetObjectDiff = (
    newObj: Record<string, any>,
    oldObj: Record<string, any>
) => Record<
    string,
    {
        action: 'added' | 'removed' | 'changed'
        previousValue: any
        newValue: any
    }
>

export const getObjectDiff: IGetObjectDiff = (newObj, oldObj) => {
    const flatNewObj: typeof newObj = flat(newObj)
    const flatOldObj: typeof oldObj = flat(oldObj)

    const keysSeen = []
    const diffs: Record<string, any> = {}
    for (const key in flatNewObj) {
        keysSeen.push(key)
        const isAdded = !(key in flatOldObj)
        const isDifferent = flatNewObj[key] !== flatOldObj[key]

        if (isAdded) {
            diffs[key] = {
                action: 'added',
                previousValue: flatOldObj[key],
                updatedValue: flatNewObj[key],
            }
        } else if (isDifferent) {
            diffs[key] = {
                action: 'changed',
                previousValue: flatOldObj[key],
                updatedValue: flatNewObj[key],
            }
        }
    }

    for (const key in flatOldObj) {
        if (!keysSeen.includes(key)) {
            keysSeen.push(key)
            const isRemoved = !(key in flatNewObj)
            const isDifferent = flatNewObj[key] !== flatOldObj[key]

            if (isRemoved) {
                diffs[key] = {
                    action: 'removed',
                    previousValue: flatOldObj[key],
                    updatedValue: flatNewObj[key],
                }
            } else if (isDifferent) {
                diffs[key] = {
                    action: 'changed',
                    previousValue: flatOldObj[key],
                    updatedValue: flatNewObj[key],
                }
            }
        }
    }

    const unflattenedDiffObject: typeof diffs = flat.unflatten(diffs)
    return unflattenedDiffObject
}

export default getObjectDiff
