import { IFormFields, IUseFormInstance } from '@/hooks/useForm'
import Box, { BoxProps } from '@mui/material/Box'
import React, { useContext } from 'react'
import { FieldValues, SubmitErrorHandler, SubmitHandler } from 'react-hook-form'

export type IOnSubmit<FormFields extends FieldValues = any> =
    SubmitHandler<FormFields>

export type IOnError<FormFields extends FieldValues = any> =
    SubmitErrorHandler<FormFields>

export type IFormProps<FormFields extends FieldValues = any> = BoxProps<
    'form',
    {
        form: IUseFormInstance<FormFields>
        onSubmit: IOnSubmit<FormFields>
        onError?: IOnError<FormFields>
        [key: string]: any
    }
>

export type IFormContext<FormFields extends FieldValues = IFormFields> =
    IUseFormInstance<FormFields>

export const FormContext = React.createContext<
    IFormContext | Record<string, any>
>({})

export function useFormContext<FormFields extends FieldValues = IFormFields>() {
    const formContext = useContext(FormContext)
    return formContext as IFormContext<FormFields>
}

export const Form: React.FC<IFormProps> = ({
    form: formInstance,
    onSubmit,
    onError,
    children,
    ...otherProps
}) => {
    // 1. variables/state
    //---

    // 2. handler functions
    const handleSubmit = formInstance.handleSubmit(onSubmit, onError)

    // 3. render functions
    // ---

    // 4. effects
    // ---

    return (
        <FormContext.Provider value={formInstance}>
            <Box<'form'>
                width="100%"
                {...otherProps}
                component="form"
                onSubmit={handleSubmit}
                noValidate
            >
                {children}
            </Box>
        </FormContext.Provider>
    )
}

export default Form
