import { Event } from '@/enums'
import { Analytics } from '@/util/analytics'
import getViewportData from '@/util/getViewportData'
import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import useragent, { Agent } from 'useragent'

const commitRef = process.env.REACT_APP_COMMIT_REF
const buildId = process.env.REACT_APP_BUILD_ID

export class AnalyticsProxy extends Analytics {
    screenName?: string
    websitePage?: string
    userAgentData: Agent

    constructor(websitePage?: string, screenName?: string) {
        super()
        this.userAgentData = useragent.parse(navigator.userAgent)
        this.screenName = screenName
        this.websitePage = websitePage
    }

    async track(eventName: Event, _properties: Record<string, any> = {}) {
        const viewportData = getViewportData()
        const properties = {
            screenName: this.screenName,
            websitePage: this.websitePage,
            commitRef: commitRef,
            buildId: buildId,
            isMobile: ['iphone', 'smartphone'].includes(
                this.userAgentData.device.toString().toLowerCase()
            ),
            browserName: this.userAgentData?.family ?? null,
            browserVersion: this.userAgentData?.toVersion() ?? null,
            osName: this.userAgentData?.os?.family ?? null,
            osVersion: this.userAgentData?.os?.toVersion() ?? null,
            deviceModel: this.userAgentData?.device?.family ?? null,
            viewportHeight: viewportData.height,
            viewporWidth: viewportData.width,
            viewportOrientation: viewportData.orientation,
            url: window?.location?.href,
            path: window?.location?.pathname,
            env: process.env.REACT_APP_ENV,
            ..._properties,
        }

        return super.track(eventName, properties)
    }
}

export const analyticsPropsCtx = createContext<{
    screenName?: string
    websitePage?: string
}>({})

export type IAnalyticsPropsProvider = React.FC<{
    screenName?: string
    websitePage?: string
}>

export const AnalyticsPropsProvider: IAnalyticsPropsProvider = ({
    screenName,
    ...otherProps
}) => {
    const value = useMemo(() => ({ screenName }), [screenName])
    return <analyticsPropsCtx.Provider {...otherProps} value={value} />
}

export type IUseAnalytics = (
    props?: Record<string, unknown> & {
        screenName?: string
    }
) => { analytics: AnalyticsProxy; AnalyticsPropsProvider: React.FC }

export const useAnalytics: IUseAnalytics = ({
    screenName: screenNameProp,
    ...screenProps
} = {}) => {
    // 1. variables/state
    const location = useLocation()
    const { screenName: screenNameCtx } = useContext(analyticsPropsCtx)
    const screenName = screenNameProp ?? screenNameCtx
    const isNewScreenName =
        screenName === screenNameProp && screenNameProp !== screenNameCtx
    const analytics = useMemo(
        () => new AnalyticsProxy(location.pathname, screenName),
        [location.pathname, screenName]
    )
    const [isAnalyticsReady, setIsAnalyticsReady] = useState(analytics.isLoaded)
    const AnalyticsPropsProviderWithDefaults = useMemo(() => {
        const _AnalyticsPropsProviderWithDefaults: typeof AnalyticsPropsProvider =
            (props) => (
                <AnalyticsPropsProvider screenName={screenName} {...props} />
            )
        _AnalyticsPropsProviderWithDefaults.displayName =
            AnalyticsPropsProvider.displayName
        return _AnalyticsPropsProviderWithDefaults
    }, [screenName])

    // 2. helper functions
    // ---

    // 3. effects
    // 3.1 updates states when analytics is ready
    useEffect(() => {
        if (!isAnalyticsReady) {
            analytics.waitForReadiness().then(() => setIsAnalyticsReady(true))
        }
    }, [])

    // 3.2 if we are at a new page, trigger the 'ViewedScreen' event
    useEffect(() => {
        if (isNewScreenName && screenNameProp && isAnalyticsReady) {
            analytics.page()
            analytics.track(Event.ViewedScreen, screenProps || {})
        }
    }, [isNewScreenName, screenNameProp, isAnalyticsReady])

    return {
        analytics,
        AnalyticsPropsProvider: AnalyticsPropsProviderWithDefaults,
    }
}

export default useAnalytics
