import { ReactComponent as EarninLogo } from '@/assets/svg/earnin-logo.svg'
import Box, { BoxProps } from '@mui/material/Box'

export type IEarninLogoHeader = React.FC<BoxProps>

export const EarninLogoHeader: IEarninLogoHeader = (props) => {
    return (
        <Box className="earnin-logo-container" {...props}>
            <EarninLogo />
        </Box>
    )
}

export default EarninLogoHeader
