/**
 * A/B test experiments ids
 * !OBS: feel free to add new items but DO NOT change or remove existing items because that would mess up metrics
 */

export enum Experiment {
    DOTCOM_E01_EMAIL_CAPTURE_ON_LANDING_PAGE = 'dotcom-e01_email_capture_on_landing_page',
}

export default Experiment
