import { Event } from '@/enums'
import useAnalytics from '@/hooks/useAnalytics'
import { omit } from '@/util'
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton'
import React from 'react'

export type ITrackedButton = React.FC<
    LoadingButtonProps & {
        name: string
        analyticsAttributes?: Record<string, unknown>
        disableAnalytics?: boolean
        target?: string
    }
>

export const TrackedButton: ITrackedButton = ({
    name,
    analyticsAttributes,
    disableAnalytics,
    variant = 'contained',
    href,
    target,
    ...otherProps
}) => {
    const { analytics } = useAnalytics()
    const trackClick: (
        evtHandler?: React.MouseEventHandler<HTMLButtonElement>
    ) => React.MouseEventHandler<HTMLButtonElement> = (onClick) => {
        return (evt) => {
            if (!disableAnalytics) {
                analytics.track(Event.UserInteracted, {
                    elementName: name,
                    component: 'Button',
                    ...analyticsAttributes,
                })
            }

            if (onClick) {
                onClick(evt)
            }
        }
    }

    return (
        <LoadingButton
            {...omit(otherProps as any, 'analyticsAttributes')}
            name={name}
            variant={variant}
            onClick={trackClick(otherProps.onClick)}
            href={href}
            {...(target && href && { target, rel: 'noopener noreferrer' })}
        />
    )
}

export default TrackedButton
