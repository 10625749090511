import useStore from '@/store'
import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export function useUtmParameters() {
    const history = useHistory()
    const utmStore = useStore(useCallback((state) => state.utm, []))
    const { set, ...utmParameters } = utmStore

    const setAndPersistLocallly = (
        key: 'utmCampaign' | 'utmSource' | 'utmContent' | 'utmMedium',
        value: any
    ) => {
        localStorage.setItem(key, value)
        return set(key, value)
    }

    useEffect(() => {
        const search = new URLSearchParams(history.location.search)
        for (const [key, value] of search) {
            if (key === 'utm_campaign') {
                setAndPersistLocallly('utmCampaign', value)
            }

            if (key === 'utm_source') {
                setAndPersistLocallly('utmSource', value)
            }

            if (key === 'utm_content') {
                setAndPersistLocallly('utmContent', value)
            }

            if (key === 'utm_medium') {
                setAndPersistLocallly('utmMedium', value)
            }
        }
    }, [history.location.search])

    return utmParameters
}

export default useUtmParameters
