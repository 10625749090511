import { useEffect } from 'react'
import {
    DefaultValues,
    FieldErrors,
    FieldValues,
    useForm as hookFormUseForm,
    UseFormProps,
    UseFormReturn,
} from 'react-hook-form'

export type IFormFields = Record<string, any>

export type IValidateFunction<FieldsObject extends FieldValues = IFormFields> =
    (
        value: any,
        {
            data,
            errors,
        }: {
            data?: IFormFields
            errors?: FieldErrors<FieldsObject>
        }
    ) => undefined | string | boolean | Promise<string | boolean | undefined>

export type IFormSchemaItem<FieldsObject extends FieldValues = IFormFields> = {
    required?: boolean | [boolean, string]
    maxLength?: number | [number, string]
    minLength?: number | [number, string]
    pattern?: RegExp | [RegExp, string]
    validate?:
        | IValidateFunction<FieldsObject>
        | [IValidateFunction<FieldsObject>, string]
}

export type IFormSchema<FieldsObject extends FieldValues = IFormFields> = {
    [Key in keyof FieldsObject]?: IFormSchemaItem<FieldsObject>
}

export type IUseFormArgs<FormFields extends FieldValues> = {
    schema?: IFormSchema<FormFields>
    _libOverrides?: UseFormProps<FormFields>
}

export type IUseFormInstance<FormFields extends FieldValues> =
    UseFormReturn<FormFields> & {
        schema?: IFormSchema<FormFields>
    }

export function useForm<FormFields extends FieldValues>(
    initialValues: DefaultValues<FormFields>,
    options: IUseFormArgs<FormFields> = {}
): IUseFormInstance<FormFields> {
    const { schema, _libOverrides } = options
    const hookFormInstance = hookFormUseForm<FormFields>({
        defaultValues: initialValues,
        mode: 'all',
        reValidateMode: 'onChange',
        criteriaMode: 'all',
        shouldFocusError: true,
        ..._libOverrides,
    })

    const formInstance: IUseFormInstance<FormFields> = {
        ...hookFormInstance,
        schema,
    }

    useEffect(() => {
        hookFormInstance.trigger()
    }, [])

    return formInstance
}

export default useForm
