/**
 * Paths for the application routes
 */

export enum Paths {
    HomePage = '/home', // This path became base path '/' on app2
    Questionnaire = '/questionnaire',
    JoinUs = '/join-us',
    Referrals = '/referral-landing',
    VerifyPhone = '/verify',
    YouAreIn = '/you-are-in',
    YouAreAllSet = '/all-set',
    VerifyDevice = '/verifyDevice',
    VerifyDeviceResult = '/verifyDeviceResult',
    VerifyPersonalEmail = '/verify-email',
    VerifyWorkEmail = '/verify-work-email',
    AkoyaCallback = '/akoya-callback',
    AkoyaDeepLink = 'earninakoya://callback',
    Blocked = '/blocked',
    ResetPassword = '/passwordreset',
    ResetPasswordResult = '/passwordresetresult',
    OutsideUsaRegex = '/outside-usa*',
    InternationalBlockFAQ = 'https://help.earnin.com/hc/en-us/sections/4408633601171-Accessing-the-Earnin-App',
}

export default Paths
