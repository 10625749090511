import EarninLogoHeader from '@/components/EarninLogoHeader'
import ScreenName from '@/enums/ScreenName'
import { useAnalytics } from '@/hooks'
import Box from '@mui/material/Box'
import React, { ReactNode } from 'react'
import { Helmet } from 'react-helmet'

export type IPage = React.FC<{
    screenName: ScreenName
    id?: string
    browserTitle?: string
    metaDescription?: string
    head?: ReactNode
    screenProps?: Record<string, unknown>
    hideLogo?: boolean
    centerLogo?: boolean
    centerBody?: boolean
    body?: ReactNode
    footer?: ReactNode
}>

export const Page: IPage = ({
    id,
    screenName,
    screenProps,
    browserTitle,
    metaDescription,
    hideLogo = false,
    centerLogo = false,
    centerBody = false,
    footer,
    body,
    children,
    head,
}) => {
    const { AnalyticsPropsProvider } = useAnalytics({
        screenName,
        ...screenProps,
    })

    return (
        <AnalyticsPropsProvider>
            <Helmet>
                {browserTitle && <title>{browserTitle}</title>}
                {metaDescription && (
                    <meta name="description" content={metaDescription} />
                )}
                {head}
            </Helmet>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        maxWidth: '140ch',
                        padding: {
                            xs: '0 2rem',
                            md: '0 4rem',
                        },
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    {/* header */}
                    <Box
                        component="header"
                        sx={{
                            marginTop: '2rem',
                            display: 'flex',
                            justifyContent: {
                                xs: 'center',
                                ...(!centerLogo && {
                                    md: 'flex-start',
                                }),
                            },
                        }}
                    >
                        {!hideLogo && <EarninLogoHeader />}
                    </Box>

                    {/* body */}
                    <Box
                        id={id}
                        component="main"
                        sx={{
                            margin: `2rem ${centerBody ? 'auto' : 0}`,
                        }}
                    >
                        {body || children}
                    </Box>

                    {/* footer */}
                    <Box component="footer" sx={{ padding: '2rem 0' }}>
                        {footer}
                    </Box>
                </Box>
            </Box>
        </AnalyticsPropsProvider>
    )
}

export default Page
