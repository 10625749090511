import pollUntilResolved, {
    IPollUntilResolvedOptions,
} from '@/util/pollUntilResolved'
import logger from '@/util/logger'

export class Hotjar {
    status: 'not loaded' | 'loading' | 'loaded' | 'failed'
    private _loadingPromise: Promise<any> | null

    constructor() {
        this.status = 'not loaded'
        this._loadingPromise = null
        this.waitForReadiness()
    }

    get isLoaded() {
        return this.status === 'loaded'
    }

    get hotjar() {
        // if (!this.isLoaded) {
        //     throw new Error('Hotjar is not loaded')
        // }
        return window?.hj
    }

    waitForReadiness({
        cb,
        options = {},
    }: {
        cb?: () => unknown
        options?: IPollUntilResolvedOptions
    } = {}): Promise<unknown> {
        if (this.isLoaded) {
            return Promise.resolve(cb ? cb() : true)
        }

        if (
            this.status === 'loading' &&
            this._loadingPromise instanceof Promise
        ) {
            return this._loadingPromise.then(() => (cb ? cb() : true))
        }

        this.status = 'loading'
        this._loadingPromise = new Promise(async (resolve, reject) => {
            try {
                const _hotjar = await pollUntilResolved<Window['hj']>(
                    () => this.hotjar,
                    { maxAttempts: 100, poolIntervalMs: 100, ...options }
                )
                if (_hotjar) {
                    this.status = 'loaded'
                    resolve(cb ? cb() : true)
                }
            } catch (err) {
                this.status = 'failed'
                const error = new Error('Failed to load Hotjar')
                reject(error)
            }
        })

        return this._loadingPromise
    }

    async identify(
        publicUserId?: string | null,
        userProps?: Record<string, any>
    ) {
        try {
            this?.hotjar('identify', publicUserId, userProps)
        } catch (err: any) {
            logger.error('Hotjar: failed to identify user', err)
        }
    }
}

export const hotjar = new Hotjar()

export default hotjar
