import useResizeObserver from '@react-hook/resize-observer'
import { useLayoutEffect, useState } from 'react'

export function useSize(target: any) {
    const [size, setSize] = useState<any>()

    useLayoutEffect(() => {
        if (target && target.current) {
            setSize(target.current.getBoundingClientRect())
        }
    }, [target])

    // Where the magic happens
    useResizeObserver(target, (entry) => setSize(entry.contentRect))
    return size
}

export default useSize
