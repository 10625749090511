import { Event, Experiment, LocalStorage } from '@/enums'
import useStore from '@/store'
import { useExperiment as useOptimizelyExperiment } from '@optimizely/react-sdk'
import { useCallback, useEffect } from 'react'
import useAnalytics from '../useAnalytics'
import logger from '@/util/logger'

const timedOutExperiments = new Set()
const experimentsToIgnore = new Set()

const getExperimentsToIgnoreFromQueryParams = () => {
    try {
        const search = new URLSearchParams(location.search)
        for (const [key, value] of search) {
            if (key === 'skip_experiment') {
                experimentsToIgnore.add(value)
            }
        }
    } catch (err) {
        logger.error(
            `Failed get experiments to ignore from query params: ${err}`
        )
    }
    return experimentsToIgnore
}
getExperimentsToIgnoreFromQueryParams()

export type IUseExperimentOptions = {
    options?: Record<string, any>
    overrides?: Record<string, any>
    lazyInit?: boolean
}

export const useExperiment = <
    VariationType extends string | null = string | null
>(
    experimentName: Experiment,
    { options, overrides, lazyInit }: IUseExperimentOptions = {}
) => {
    // 1. variables/state
    const { set: setExperimentsState, ...experiments } = useStore(
        useCallback((state) => state.experiments, [])
    )

    const { analytics } = useAnalytics({
        screenName: options?.screenName,
    })

    let [variation, clientReady, didTimeout] = useOptimizelyExperiment(
        experimentName,
        options,
        overrides
    )

    if (didTimeout) {
        timedOutExperiments.add(experimentName)
    }
    didTimeout = didTimeout || timedOutExperiments.has(experimentName)

    const isExperimentAlreadyStarted = experimentName in experiments

    let canExperimentBeStarted =
        !isExperimentAlreadyStarted && !!variation && clientReady && !didTimeout
    const noVariationWasLoaded =
        didTimeout || (!variation && !didTimeout && clientReady)
    const variationIsLoading = (!didTimeout && !clientReady) || !variation

    if (
        process.env.REACT_APP_ENABLE_EXPERIMENTS === 'false' ||
        getExperimentsToIgnoreFromQueryParams().has('all') ||
        getExperimentsToIgnoreFromQueryParams().has(experimentName)
    ) {
        variation = 'control'
        canExperimentBeStarted = false
    } else if (isExperimentAlreadyStarted) {
        variation = experiments[experimentName]
    } else if (noVariationWasLoaded) {
        variation = 'control'
    } else if (variationIsLoading) {
        variation = 'loading'
    }

    // 2. helper functions
    const startExperiment = useCallback(async () => {
        if (canExperimentBeStarted) {
            try {
                await analytics.track(Event.StartedExperiment, {
                    experimentId: experimentName,
                    variantId: variation,
                })
                setExperimentsState(experimentName, variation)
            } catch (err) {
                logger.error(
                    `Failed to track event "${
                        Event.StartedExperiment
                    }" for experiment "${experimentName}": ${String(err)}`,
                    err as Error
                )
            }
        }
    }, [isExperimentAlreadyStarted, experimentName, variation])

    const forceExperimentVariation = (variationName: string) => {
        setExperimentsState(experimentName, variationName)
    }

    // 3. effects
    // 3.1 syncs state of Experiments store with localStorage
    useEffect(() => {
        localStorage.setItem(
            LocalStorage.Experiments,
            JSON.stringify(experiments)
        )
    }, [experiments])

    // 3.2 start experiment when all necessary variables are ready
    useEffect(() => {
        if (!lazyInit) {
            startExperiment()
        }
    }, [lazyInit, canExperimentBeStarted, startExperiment])

    return {
        variation: variation as VariationType,
        startExperiment,
        forceExperimentVariation,
        clientReady,
        didTimeout,
    }
}

export const getExperimentsState = () => useStore.getState().experiments

export default useExperiment
