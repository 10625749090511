type IDeepMergeTwoOptions = {
    mutate?: boolean
}

const isObject = (value: any) =>
    !!value && typeof value === 'object' && !Array.isArray(value)

export const deepMergeTwo = <
    T1 extends Record<string, any>,
    T2 extends Record<string, any>
>(
    targetObject: T1,
    sourceObject: T2,
    { mutate = false }: IDeepMergeTwoOptions = {}
): Record<string, any> => {
    if (!sourceObject) {
        return targetObject
    }

    if (!isObject(targetObject) || !isObject(sourceObject)) {
        throw new Error('deepMerge: target and source must be objects')
    }

    let newObject: Record<string, any>
    if (mutate) {
        newObject = Object.assign(targetObject, sourceObject)
    } else {
        newObject = Object.assign({}, targetObject, sourceObject)
    }

    Object.entries(sourceObject).forEach(([sourceKey, sourceValue]) => {
        const targetValue = targetObject?.[sourceKey]
        if (sourceValue === undefined) {
            newObject[sourceKey] = targetValue
        } else if (isObject(targetValue) && isObject(sourceValue)) {
            newObject[sourceKey] = deepMergeTwo(targetValue, sourceValue)
        }
    })

    return newObject
}

export const deepMerge = (
    ...objects: Array<Record<string, any> | undefined>
) => {
    return [{}]
        .concat(objects || {})
        .reduce((a, b) => deepMergeTwo(a, b, { mutate: false }))
}

export default deepMerge
