import React, { useMemo } from 'react'
import { Redirect } from 'react-router-dom'

interface RedirectOnConditionProps {
    condition: boolean | (() => boolean)
    redirectPath: string
    isInternalRoute: boolean
    children: any
}
export const RedirectOnCondition: React.FC<RedirectOnConditionProps> = ({
    condition,
    redirectPath,
    isInternalRoute = true,
    children,
}) => {
    const shouldRedirect = useMemo(
        () => (typeof condition === 'function' ? condition() : condition),
        [condition]
    )

    if (shouldRedirect) {
        if (isInternalRoute) {
            return <Redirect to={{ pathname: redirectPath }} />
        } else {
            window.location.href = redirectPath
            return null
        }
    }

    return children
}

export default RedirectOnCondition
