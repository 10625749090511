const validateName = (name: string) => {
    const re = /^[A-Za-z]+$/
    return re.test(name)
}

const validateEmail = (email: string) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
}

const validatePassword: (password: string) => [boolean, string] = (
    password: string
) => {
    let validationError = ''

    if (!(password.length >= 6)) {
        validationError = 'Must be longer than 6 characters.'
    }

    if (!/[A-Z]/.test(password)) {
        validationError = 'Must have at least 1 uppercase letter.'
    }

    if (!/[a-z]/.test(password)) {
        validationError = 'Must have at least 1 lowercase letter.'
    }

    if (!/[0-9]/.test(password)) {
        validationError = 'Must have at least 1 number.'
    }

    const isValid = !validationError
    return [isValid, validationError]
}

const validatePhone = (phone: string) => {
    const re = /^[0-9]+$/
    return phone.length === 10 && re.test(phone)
}

const isProbablyMobile = () => {
    return navigator.userAgent.indexOf('Mobi') > -1
}

const buildDownloadLink = (
    campaign: string,
    content: string,
    source: string,
    medium: string
) => {
    const params = new URLSearchParams()

    if (source && source.toLowerCase() === 'referrals') {
        params.append(
            'adj_t',
            process.env.REACT_APP_ADJUST_REFERRAL_DOWNLOAD_TRACKER
        )
    } else {
        params.append('adj_t', process.env.REACT_APP_ADJUST_DOWNLOAD_TRACKER)
    }

    if (campaign) {
        params.append('adj_campaign', campaign)
    }

    if (content) {
        params.append('adj_creative', content)
    }

    if (medium) {
        params.append('adj_group', medium)
    }

    if (source) {
        params.append('adj_label', source)
    }

    const downloadLink = `${
        process.env.REACT_APP_ADJUST_TRACKER_HOST
    }?${params.toString()}`

    return downloadLink
}

const omit = (
    obj: Record<string, unknown>,
    omitKey: string | Array<string>
) => {
    const keysToOmit = ([] as Array<string>).concat(omitKey)
    return Object.keys(obj).reduce(
        (result: Record<string, unknown>, key: string) => {
            if (!keysToOmit.includes(key)) {
                result[key] = obj[key]
            }
            return result
        },
        {}
    )
}

const cleanPhone = (phone: string): string => {
    return phone.replace(/[-+()\s]/g, '')
}

export {
    validateEmail,
    validatePassword,
    validateName,
    validatePhone,
    isProbablyMobile,
    buildDownloadLink,
    omit,
    cleanPhone,
}
