import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export type IUseScrollToTopOnNavigation = () => void

export const useScrollToTopOnNavigation: IUseScrollToTopOnNavigation = () => {
    const history = useHistory()

    // scroll to top whenever browser navigation happens
    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0)
        })
        return () => {
            unlisten()
        }
    }, [history])
}

export default useScrollToTopOnNavigation
