import flat from 'flat'
import { deepMergeTwo } from '../deepMerge'

type IRedactValuesFromObject = <T extends Record<string, any>>(
    object: T,
    valuesToRedact: Array<string>,
    options?: { redactString?: string; mutate?: boolean }
) => T

export const redactValuesFromObject: IRedactValuesFromObject = (
    object,
    valuesToRedact,
    { redactString = '######', mutate = false } = {}
) => {
    const flatObject = flat.flatten<typeof object, Record<string, any>>(object)
    const lowerCasedValuesToRedact = valuesToRedact.map((item) =>
        item.toLowerCase().trim()
    )
    const redactedFlatObjectEntries = Object.entries(flatObject).map(
        ([key, value]) => {
            const shouldValueBeRedacted = key
                .split('.')
                .some((item, index, array) => {
                    const isLast = index === array.length - 1
                    if (!isLast) {
                        return false
                    }
                    const shouldRedact = lowerCasedValuesToRedact.includes(
                        item.toLocaleLowerCase().trim()
                    )
                    return shouldRedact
                })
            if (shouldValueBeRedacted) {
                return [key, redactString]
            }
            return [key, value]
        }
    )
    const redactedFlatObject = Object.fromEntries(redactedFlatObjectEntries)
    const redactedObject = flat.unflatten<
        typeof redactedFlatObject,
        typeof object
    >(redactedFlatObject)

    if (mutate) {
        deepMergeTwo(object, redactedObject, { mutate })
        return object
    }

    return redactedObject
}

export default redactValuesFromObject
