export type Orientation = 'landscape' | 'portrait' | 'unknown'

export const getViewportData = (): {
    width: number | 'unknown'
    height: number | 'unknown'
    orientation: Orientation
} => {
    const width =
        window?.innerWidth ??
        document?.documentElement?.clientWidth ??
        document?.body?.clientWidth ??
        'unknown'

    const height =
        window?.innerHeight ??
        document?.documentElement?.clientHeight ??
        document?.body?.clientHeight ??
        'unknown'

    const orientationData = window?.screen?.orientation?.type
    let orientation: Orientation = 'unknown'

    if (orientationData) {
        orientation = orientationData.split('-')[0] as Orientation
    }

    if (!['landscape', 'portrait'].includes(orientation)) {
        orientation = window?.matchMedia?.('(orientation: portrait)')?.matches
            ? 'portrait'
            : window?.matchMedia?.('(orientation: landscape)')?.matches
            ? 'landscape'
            : 'unknown'
    }

    return { width, height, orientation }
}

export default getViewportData
