import escapeRegExp from '@/util/escapeRegExp'
import redactValuesFromObject from '@/util/redactValuesFromObject'
import { datadogLogs, HandlerType, StatusType } from '@datadog/browser-logs'

export const logger = datadogLogs.createLogger('logger', {
    level: (process.env.REACT_APP_LOGGER_LEVEL as StatusType) || 'debug',
    handler: (process.env.REACT_APP_LOGGER_HANDLER as HandlerType) || 'http',
})

const valuesToRedactFromLogs = (process.env.REACT_APP_LOGGER_REDACT_WORDS ?? '')
    .split(',')
    .map((item) => item.toLowerCase().trim())

export const redactValuesFromLogs = <T extends Record<string, any>>(
    object: T,
    extraValuesToRedact?: Array<string>
) =>
    redactValuesFromObject(
        object,
        [...valuesToRedactFromLogs, ...(extraValuesToRedact ?? [])],
        { mutate: true, redactString: 'REDACTED' }
    )

export const checkShouldIgnoreLog = (logMessage: string) => {
    const patternsToIgnore =
        process.env.REACT_APP_LOGGER_PATTERNS_TO_IGNORE?.trim()
    if (!patternsToIgnore) {
        return false
    }
    const logPatternsToIgnore = patternsToIgnore
        .split('|')
        .map((item) => escapeRegExp(item.trim()))
        .join('|')
    const logPatternsToIgnoreRegExp = new RegExp(logPatternsToIgnore, 'ig')
    const shouldIgnoreLog = logPatternsToIgnoreRegExp.test(logMessage)
    return shouldIgnoreLog
}

export default logger
