import React, { ReactElement } from 'react'
import { useFeature } from '@/hooks'
import { Feature } from '@/enums'
import { Box, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

const CheckHomePage = ({ children }: { children: ReactElement }) => {
    const { isActive: isNewHomePageEnabled, isReady } = useFeature(
        Feature.newHomePage
    )

    if (!isReady) {
        return (
            <Box
                sx={{
                    width: '100%',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 6,
                }}
            >
                <Box sx={{ mx: 'auto' }}>
                    <CircularProgress size={28} sx={{ color: '#4361E1' }} />
                </Box>
                <Typography
                    sx={{
                        textAlign: 'center',
                        fontWeight: 600,
                        fontSize: 20,
                        marginTop: 3,
                        color: 'black',
                        opacity: 0.92,
                    }}
                >
                    Loading ...
                </Typography>
            </Box>
        )
    }

    if (isNewHomePageEnabled) {
        // Redirect to app2.earnin.com/ instead of /home
        const newWebAppHomeNewUrl = new URL(
            '/',
            process.env.REACT_APP_NEW_WEB_APP_HOST
        )

        // Copy all search params to new page
        newWebAppHomeNewUrl.search = location.search
        window.location.href = newWebAppHomeNewUrl.href
        return <></>
    }

    return children
}

export default CheckHomePage
