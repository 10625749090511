/**
 * Analytics screen names
 * !OBS: feel free to add new items but DO NOT change or remove existing items because that would mess up metrics
 */

export enum ScreenName {
    HomePage = 'Web onboarding funnel - Informative landing page',
    Questionnaire = 'Web onboarding funnel - Questionnaire page',
    JoinUs = 'Web onboarding funnel - Sign Up Form Page',
    ReferralsLanding = 'Web onboarding funnel - Referrals Sign Up Form Page',
    VerifyPhone = 'Web onboarding funnel - Phone Verification Page',
    YouAreIn = 'Web onboarding funnel - Account Info Completed Page',
    YouAreAllSet = 'Web onboarding funnel - Registration Completion Page',
    ResetPassword = 'web password reset',
    VerifyDevice = 'web device verification',
    VerifyPersonalEmail = 'personal email verification',
    VerifyWorkEmail = 'work email verification',
    InternationalBlock = 'International Block',
    AkoyaCallback = 'Akoya Callback',
    CookieBanner = 'Web onboarding funnel - Cookie Banner test',
}

export default ScreenName
