import { LocalStorage, Feature, Experiment } from '@/enums'
import analytics from '@/util/analytics'
import getObjectDiff from '@/util/getObjectDiff'
import flatten from 'flat'
import create from 'zustand'

export type IStoreSet<Props = Record<string, any>> = (
    keyOrObj: Partial<Props> | keyof Props,
    value?: any
) => void

type PropsWithSet<Props = Record<string, any>> = Props & {
    set: IStoreSet<Props>
}

export type IStore = {
    session: PropsWithSet<{
        publicUserId: string | null
        anonymousUserId: string
        deviceId: string
    }>
    signUp: PropsWithSet<{
        firstName: string
        lastName: string
        email: string
        password: string
        phone: string
        referralCode: string
        hasAgreedToESignAgreement: boolean
        hasAgreedToSMSCompliance: boolean
        automaticSmsAppDownloadLinkSent: boolean
        employer: {
            id: string | number
            name: string
            logo?: string | null
        } | null
    }>
    utm: PropsWithSet<{
        utmCampaign: string
        utmContent: string
        utmSource: string
        utmMedium: string
    }>
    experiments: PropsWithSet<Record<Experiment, string>>
    featureFlags: PropsWithSet<
        Record<
            Feature,
            { isActive: boolean; variables: Record<string, any> } | null
        >
    >
    completedFunnelSteps: Set<string>
    completeFunnelStep: (funnelStep: string) => void
}

// const storeLocalStorage = (
//     key: string,
//     set: (cb: (state: Record<string, unknown>) => void) => void
// ) => {
//     return (value: string) =>
//         set((state) => {
//             localStorage.setItem(key, value)
//             state[key] = value
//         })
// }

export const useStore = create<IStore>((set, get) => {
    function factorySetFunc<ContextObject = IStore>(
        prefixPath: keyof IStore,
        cb?: () => void
    ) {
        const _set: IStoreSet<Partial<Omit<ContextObject, 'set'>>> = (
            keyOrObj,
            value
        ) => {
            const state = get()
            const stateInContext = state[prefixPath]

            let flattenedObject: Record<string, any>
            const isFirstParamObject =
                (keyOrObj as Record<string, any>)?.constructor?.name ===
                'Object'
            if (isFirstParamObject) {
                flattenedObject = {
                    [prefixPath]: {
                        ...stateInContext,
                        ...(keyOrObj as Record<string, any>),
                    },
                }
            } else {
                if (value === undefined) {
                    throw new Error(
                        `set/${prefixPath}/${
                            keyOrObj as string
                        }: property name was provided but value is missing. Provide the value to be set.`
                    )
                }
                const keyPath = `${keyOrObj as string}`.replace(/\.+/, '.')
                flattenedObject = {
                    [prefixPath]: { ...stateInContext, [keyPath]: value },
                }
            }

            const unflattenedObject = flatten.unflatten(flattenedObject)
            set(unflattenedObject as IStore)
            cb?.()
        }

        return _set
    }

    const store: IStore = {
        session: {
            set: factorySetFunc<IStore['session']>('session'),
            publicUserId: analytics.getUserId() || null,
            anonymousUserId: analytics.getAnonymousUserId(),
            deviceId: analytics.getDeviceId(),
        },
        signUp: {
            set: factorySetFunc<IStore['signUp']>('signUp'),
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            phone: '',
            referralCode: '',
            hasAgreedToESignAgreement: false,
            hasAgreedToSMSCompliance: false,
            automaticSmsAppDownloadLinkSent: false,
            employer: null,
        },
        utm: {
            set: factorySetFunc<IStore['utm']>('utm'),
            utmCampaign: localStorage.getItem(LocalStorage.UtmCampaign) || '',
            utmContent: localStorage.getItem(LocalStorage.UtmContent) || '',
            utmSource: localStorage.getItem(LocalStorage.UtmSource) || '',
            utmMedium: localStorage.getItem(LocalStorage.UtmMedium) || '',
        },
        experiments: {
            ...JSON.parse(
                localStorage.getItem(LocalStorage.Experiments) ?? '{}'
            ),
            set: factorySetFunc<IStore['experiments']>('experiments'),
        },
        featureFlags: {
            set: factorySetFunc<IStore['featureFlags']>('featureFlags'),
            ...Object.values(Feature).reduce<Record<Feature, any>>(
                (result, item) => {
                    result[item as Feature] = null
                    return result
                },
                {} as Record<Feature, any>
            ),
        },
        completedFunnelSteps: new Set(),
        completeFunnelStep: (funnelStep: string) =>
            set((state) => {
                state.completedFunnelSteps.add(funnelStep)
            }),
    }

    return store
})

if (process.env.REACT_APP_ENABLE_STATE_STORE_UPDATE_DEBUG === 'true') {
    useStore.subscribe((newState, oldState) => {
        console.group('=== STORE STATE UPDATES DEBUG ===')
        console.group('⏮️ OLD STATE')
        console.log(oldState)
        console.groupEnd()
        console.group('🔁 UPDATES')
        console.log(getObjectDiff(newState, oldState))
        console.groupEnd()
        console.group('⏭️ NEW STATE')
        console.log(newState)
        console.groupEnd()
        console.groupEnd()
    })
}

export default useStore
