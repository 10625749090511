import { checkShouldIgnoreLog, redactValuesFromLogs } from '@/util/logger'
import { datadogLogs } from '@datadog/browser-logs'
import { setLogger } from '@optimizely/react-sdk'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'

datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_TOKEN,
    site: process.env.REACT_APP_DATADOG_SITE,
    forwardErrorsToLogs: true,
    sampleRate: 100,
    env: process.env.REACT_APP_ENV,
    service: process.env.REACT_APP_NAME,
    version: process.env.REACT_APP_VERSION,
    beforeSend(log) {
        const shouldIgnoreLog = checkShouldIgnoreLog(log.message)
        if (shouldIgnoreLog) {
            return false
        }
        redactValuesFromLogs(log)
    },
})

if (process.env.REACT_APP_ENABLE_ANALYTICS_TRACKING_DEBUG !== 'true') {
    setLogger(null)
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
