/**
 * Analytics events
 * !OBS: feel free to add new items but DO NOT change or remove existing items because that would mess up metrics
 */

export enum Event {
    StartedExperiment = 'User started feature test',
    UserInteracted = 'User interacted with element',
    ViewedScreen = 'User viewed screen',
    Conversion = 'Web Account Creation',
    FormError = 'Web Form Validation Error',
    UserSubmittedSignUpEmail = 'User submitted sign up email',
}

export default Event
